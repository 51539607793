@use './../../scss/' as *;

.infinity {
    margin-top: 0;
    margin-bottom: 0;
    @include mobile {
        margin-top: -50px;
        margin-bottom: 100px;
    }
}

.slider-infinity {
    padding-top: 100px;
    width: 75vw;
    height: auto;
    margin: auto;
    overflow: hidden;
    @include mobile {
        width: 90vw;
        margin-top: 300px;
        margin-bottom: 0;
    }
    
}
.slider-infinity .slide-track {
    display: flex;
    animation: scroll 220s linear infinite;
    -webkit-animation: scroll 220s linear infinite;
    width: calc(800px * 28);
    border-top: solid 2px $principal-dark; 
    border-bottom: solid 2px $principal-dark;    
    padding-top: 20px;
}
.slider-infinity .slide {    
    display: flex;   
    
}
.slider-infinity .slide p {
    color: white;
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;   
    font-size: 18px;
    @include mobile {
        font-size: 15px;
    }

    
}

.slide i {
    color: $principal-dark;
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -4px;
}

@keyframes scroll {
    0% {
        -webkit-transform: translateX(400px);
        transform: translateX(400px);  
    }
    100% {
        -webkit-transform: translateX(calc(-800px * 14));
        transform: translateX(calc(-800px * 14));
    }    
}