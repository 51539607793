@use '../../scss/' as *;

@keyframes criss-cross-left {
    0% {
      left: -20px;
    }
    50% {
        left: 50%;
        
        width: 20px;
        height: 20px;
    }
    100% {
        left: 50%;
        width: 375px;
        height: 375px;

    }
  }
  
@keyframes criss-cross-right {
    0% {
        right: -20px;
    }
    50% {
        right: 50%;
        width: 20px;
        height: 20px;

    }
    100% {
        right: 50%;
        width: 375px;
        height: 375px;

    }
}

.btn-action {
    padding: 11px 45px;
    border: 2px solid $main-color;
    border-radius: 27px;
    transition: color 0.4s linear;
    position: relative;
    overflow: hidden;
    display: inline-block;
    font-family: $font-main;
    &:hover {
        color: $white;
        &::before {
            -webkit-animation: criss-cross-left 0.8s both;
            animation: criss-cross-left 0.8s both;
            -webkit-animation-direction: alternate;
            animation-direction: alternate;           
        }
        &::after {
            -webkit-animation: criss-cross-right 0.8s both;
            animation: criss-cross-right 0.8s both;
            -webkit-animation-direction: alternate;
            animation-direction: alternate;           
        }
    }

    &::before,
    &::after {
        position: absolute;
        z-index: -1;
        top: 50%;
        content: "";
        width: 20px;
        height: 20px;
        background-color: $main-color;
        border-radius: 50%;
     
    }
    &::before {
        left: -20px;
        transform: translate(-50%, -50%);       
    }
    &::after {
        right: -20px;
        transform: translate(50%, -50%);       
    }

}

.btn-action2 {
    margin-right: 20px;
    border: 2px solid $main-color;
    border-radius: 27px;
    transition: color 0.4s linear;
    position: relative;
    overflow: hidden;
    display: inline-block;
    font-family: $font-main;  
    &:hover {
        color: $white;
        &::before {
            -webkit-animation: criss-cross-left 0.8s both;
            animation: criss-cross-left 0.8s both;
            -webkit-animation-direction: alternate;
            animation-direction: alternate;           
        }
        &::after {
            -webkit-animation: criss-cross-right 0.8s both;
            animation: criss-cross-right 0.8s both;
            -webkit-animation-direction: alternate;
            animation-direction: alternate;           
        }
    }

    &::before,
    &::after {
        position: absolute;
        z-index: -1;
        top: 50%;
        content: "";
        width: 20px;
        height: 20px;
        background-color: $main-color;
        border-radius: 50%;
     
    }
    &::before {
        left: -20px;
        transform: translate(-50%, -50%);       
    }
    &::after {
        right: -20px;
        transform: translate(50%, -50%);       
    }

}

.btn-action.style-2 {   
    background-image: -webkit-linear-gradient(290deg, #2a2a2a, #383838);
    background-image: -o-linear-gradient(290deg, #2a2a2a, #383838);
    background-image: linear-gradient(160deg, #0e854f, #00756A);
    padding: 20px 48px;
    border-radius: 10px;
    z-index: 1;
    border: solid 2px #2d8c61; 
    @include mobile {  
        padding: 11px 36px;
        font-size: 14px;
    }  
    
    &:hover {
        color: #2d8c61;
        border: solid 2px #2d8c61;        
        transition-delay: 0.3s;
    }
    &::before,
    &::after {
        background-color: $white;
    }    
}
.btn-action2.style-3 {   
    background-image: -webkit-linear-gradient(290deg, #2a2a2a, #383838);
    background-image: -o-linear-gradient(290deg, #2a2a2a, #383838);
    background-image: linear-gradient(160deg, #ffffff, #ffffff);
    color: #0e854f ;
    padding: 20px 48px;
    border-radius: 10px;
    z-index: 1;
    border: solid 2px #2d8c61; 
    @include mobile {  
        padding: 13px 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 12px;
    }  
    
    &:hover {
        color: $white;
        border: solid 2px #2d8c61;        
        transition-delay: 0.3s;      
    }
    &::before,
    &::after {
        background-image: -webkit-linear-gradient(290deg, #2a2a2a, #383838);
    background-image: -o-linear-gradient(290deg, #2a2a2a, #383838);
    background-image: linear-gradient(160deg, #0e854f, #00756A);
    }    
}



.blog-list {
    .btn-action.style-2 {
        padding: 12px 39px;
        font-size: 16px;

        @include mobile {
            padding: 12px 15px;
        }
    }
}

.view-all {
    margin-top: 72px;
    .btn-action {
        padding: 9px 32px;
        font-size: 16px;
    }
}
.road-map .view-all {
    @include tablet {
        text-align: left;
    }
}

.home-2 .view-all{
    margin-top: 52px;
    .btn-action {
        padding: 12px 51px;
        font-size: 18px;
    }
}
.home-3 .view-all .btn-action {
    padding: 12px 50px;
    font-size: 18px;
}
.load-more {
    margin-top: 46px;
    .btn-action {
        padding: 12px 39px;
    }
}

.blog-grid .load-more {
    margin-top: 31px;
}

.button-whatsapp {
    background-image: url('../../assets/images/whatsapp/wsp.png');
    background-repeat: no-repeat; 
    background-size: cover;
    position: fixed;
    right: 60px;
    bottom: 110px;
    width: 80px;
    height: 74px;
    z-index: 100;
    transition: all 0.3s ease-in;
    @include mobile {
        right: 12px;
        bottom: 70px;
        width: 60px;
        height: 60px;
    }
    &:hover {
        background-image: url('../../assets/images/whatsapp/wsp2.png');
        width: 100px;
        height: 100px;
        @include mobile {
            width: 80px;
            height: 74px;
            
        }
        .chateame {
            transform: translateY(-80px) translateX(-20px);            
            transition: all 0.3s ease-in-out;
            opacity: 1; 
            font-size: 23px; 
            width: 160px;
            height: 45px;
            @include mobile {
                opacity: 0;
            }
        }
    }
    .chateame {
        bottom: 150px;
        right: 70px;
        width: 140px;
        text-align: center;
        height: 40px;
        border-radius: 5px;
        font-size: 20px;
        color: $principal-dark;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.468);  
        padding: 5px;
        opacity: 0;    
    }
}


