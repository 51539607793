@use './../../scss/' as *;

.block-text {
    position: relative;
    @include tablet {
        text-align: center;
    }     
    .title {
        font-size: 50px;
        color: black;
        span {
            position: relative;
        }
        @include mobile {
            margin-top: 0;
            padding-top: 0;
            font-size: 36px;
        }
    }    

    .sub-title {
        position: relative;
        line-height: 3.2;
        color: black;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 36px;
            height: 5px;
            background: $principal-dark;
            @include tablet {
                left: 48%;
            }
        }
       
        @include mobile{
            font-size: 25px;
        }
    }

    .desc {
        font-size: 30px;
        line-height: 1.3;    
        color: white;
        font-weight: 100;
        font-family: Roboto;
        padding-right: 25px;
        span {
            font-weight: 700;
            color: #159e60;
        }
 
    }
    .desc1 {
        margin-top: -30px;
        color: white;
        font-size: 34px;  
        font-family: Roboto;
        font-weight: 600; 
        margin-bottom: 70px;
        @include mobile {
            font-size: 16px;
        }   
    }
    .desc2 {
        font-size: 30px;
        line-height: 1.3;    
        color: black;
        font-weight: 100;
        font-family: Roboto;
        padding-right: 0px;
 
    }
    
    &.center {
        .sub-title::after {
            left: 0;
            right: 0;            
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    h1.heading-bg {
        font-size: 240px;
        letter-spacing: 24px;
        text-transform: uppercase;
        position: absolute;
        z-index: -1;
        left: 0px;
        right: 0;
        top: -40px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include mobile {
            font-size: 30px;
            top: 50px;
            left: 27px;
        }       

        span {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #4425ad;
            -webkit-text-fill-color: rgb(0 0 0/0%);
        }
    } 

    h1.heading-bg2 {
        font-size: 240px;
        letter-spacing: 24px;
        text-transform: uppercase;
        position: absolute;
        z-index: -1;
        left: 0px;
        right: 0;
        top: -40px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include mobile {
            font-size: 30px;
            top: 50px;           
        }       

        span {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #4425ad;
            -webkit-text-fill-color: rgb(0 0 0/0%);
        }
    } 

    ul {
        padding-left: 3px;
        @include tablet{
            margin-top: 0px;
            margin-left: 70px;
        }
        @include mobile {
            margin-top: 0px;
            margin-left: 30px;

          
        }
        li {
            @include flex(flex-start, flex-start);

            i {
                margin-right: 29px;
                font-weight: 300;
                color: $main-color;
                margin-top: 4px;
                font-size: 22px;
            }
            p {
                @include mobile {
                    font-size: 15px;
                }
            }
            @include tablet{
                margin-left: 35%;
            }
            @include mobile{
                
                text-align: center;
                margin-left: 18%;
                margin-right: auto;
            }
        }

        &.nft {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
                margin-bottom: 18px;

                @include mobile {
                    width: 100%;
                }
                a {
                    font-size: 18px;
                    font-family: $font-second;

                    i {
                        margin-right: 17px;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }
                
            }
            &.s2 {
                display: block;
            }
        }
    }

}
.left {
    @include mobile{
    left: 600px;}
}

.up {
    @include mobile {
        margin-top: -120px;
        .sub-title{
            line-height: 70px;
            margin-bottom: 40px;
        }
        .heading-bg{
            margin-top: -85px;            
        }

    }
}

.heading-service{
    @include mobile {       
        margin-top: -50px;
    }
}

.no-mobile {
    @include mobile{
    font-size: 0;}
}