@use '../../scss/' as *;

.header {
    margin-top: -25px!important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: $header-height;
    @include flex(center,center);
    transition: all 0.3s ease-in-out;
    @include mobile{
        height: $header-shrink-height;
        margin-top: -15px!important;
    }


    &.is-fixed {
        margin-top: 0px!important;
        position: fixed;
        top: 0;
        box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%);
        background: white;
        height: $header-shrink-height;
        @include mobile {
            margin-top: -40px!important;
            height: 170px;
        }
    }

    .header__logo {
       img {
            width: 380px;
            padding-bottom: 30px;
            @include mobile {
                width: 210px;
                margin-left: -60px;
                padding-top: 60px;
            }
       }
    }

    .header__right {
        @include flex(center,flex-end);
        .btn-action {
            margin-right: 20px!important;
            @include mobile {
                margin-top: -20px!important;
            }
        }
        .mobile-button {
            display: none;
            position: relative;
            width: 26px;
            height: 26px;
            background-color: transparent;
            cursor: pointer;
            transition: all 0.3s ease;
            margin-left: 30px;

            @include tablet {
                display: block;
            } 
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                height: 3px;
                width: 100%;
                left: 0;
                top: 50%;
                -webkit-transform-origin: 50% 50%;
                -ms-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
            }
            
            &::before,
            &::after,
            span {
                background-color: #ffffff;
                -webkit-transition: all ease 0.3s;
                -moz-transition: all ease 0.3s;
                transition: all ease 0.3s;
            }
            &::before {
                -webkit-transform: translate3d(0, -7px, 0);
                -moz-transform: translate3d(0, -7px, 0);
                transform: translate3d(0, -7px, 0);
            }
            &::after {
                -webkit-transform: translate3d(0, 7px, 0);
                -moz-transform: translate3d(0, 7px, 0);
                transform: translate3d(0, 7px, 0);
            }
            span {
                position: absolute;
                width: 100%;
                height: 3px;
                left: 0;
                top: 50%;
                overflow: hidden;
                text-indent: 200%;
            }

            &.active {
                &::before {
                    -webkit-transform: rotate3d(0, 0, 1, 45deg);
                    -moz-transform: rotate3d(0, 0, 1, 45deg);
                    transform: rotate3d(0, 0, 1, 45deg);
                }
                &::after {
                    -webkit-transform: rotate3d(0, 0, 1, -45deg);
                    -moz-transform: rotate3d(0, 0, 1, -45deg);
                    transform: rotate3d(0, 0, 1, -45deg);
                }
                span {
                    opacity: 0;
                }
            }
        }
        .main-nav  {
            @include mobile {
                margin-top: 25px;
            }
            .menu {
                
                @include flex(center, flex-start);
                li {
                    position: relative;
                    width: 100%;
                    a {
                        position: relative;
                        display: block;  
                        color: black  
                    }
                    a::after{
                        content: "";
                        position: absolute;
                        background-color: $principal-dark;
                        height: 3px;
                        width: 0;
                        left: 0;
                        bottom: -10px;
                        transition: 0.5s;
                    }
                    a:hover:after{
                        width: 100%;
                        @include mobile {
                            width: 0;
                        }
                    }
                    .sub-menu {
                        position: absolute;
                        top: 100%;
                        left: -5px;
                        width: 120px;
                        background-color: white;
                        color: black;
                        z-index: 9999;
                        opacity: 0;
                        margin-top: -10px;
                        visibility: hidden;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        -webkit-transform: translateY(35px);
                        -moz-transform: translateY(35px);
                        -ms-transform: translateY(35px);
                        -o-transform: translateY(35px);
                        transform: translateY(35px);
                        padding: 10px 10px 4px 10px;
                        li {
                            padding: 5px 0px;
                            position: relative;
    
                            &::before {
                                content: '';
                                position: absolute;                                
                                left: 0;
                                width: 0;
                                height: 3px;
                                background: $principal-dark;
                                bottom: 0px;                              

                            }    
                            &:hover::before  {
                                width: 100%;
                                transition: width .3s ease;
                            }
    
                            a {
                                &::before,
                                &::after {
                                    display: none;
                                }
                            }                          
                        }
                       
                    }
                    &:hover .sub-menu{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(26px);                       
                    }
                }
    
                li {
                    padding-right: 44px;
    
                    &:nth-last-child(1) {
                        padding-right: 30px;
                    }

                    
                }
                
            }

          
            @include mobile {
                width: 150%;    
                margin-top: 35px; 
                margin-right: -20px;         

            }
        }
        .button {
            display: flex;

            @include mobile {
                display: none;
            }
        }
      
    }

    

    .menu-item.active > a ,
    .current-item a {
        color: $main-color;  
    }
}
.one-page .header .header__right .main-nav .menu li {
    padding-right: 28px;
}


.home-2 .header__right .button {
    display: none;
}

.one-page .header__right  .button {
    @include desktop-1500 {
        display: none;
    }
}

.home-2 .header__right .social {
    display: flex;
    @include mobile {
        display: none;
    }
}