@use './../../scss/' as *;

.page-title__body {
    background: transparent;
    position: relative;
    border-radius: 20px;  
    @include mobile {
        margin-top: -80px;
        margin-bottom: -50px;
        padding: 0 0px;
        text-align: center;
    }   
    .block-text {
        text-align: left;     
        @include mobile {
            padding-top: 0;
            text-align: center;
        }
        .sub-title {
            @include mobile {
                margin-bottom: 20px;
            &::after{
                left: 45%;
                margin-top: -80px;
                @include mobile {
                    margin-bottom: -35px;
                    }
                }   
            }     
        }
    }     
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        opacity: 0.1;
        border-radius: 20px;
        mix-blend-mode: screen;
    }
   
    
    img {
        position: absolute;
        bottom: 120px;
        margin-right: -100px;
        border: solid 0px $principal-dark;
        box-shadow:0 0 5px rgb(5, 0, 5),
                                         0 0 10px hwb(294 7% 64% / 0.355),
                                         0 0 0px rgb(194, 40, 211),  
                                         0 0 20px rgb(145, 12, 159);
        border-radius: 20px;
        @include tablet {
            position: relative;
            width: 100%;
        }

        
    }

    &.rm {
        img {
            right: 39px;
            &.s2 {
                right: 129px;
            }
        }
    }
}
.s3 {   
        position: relative;
        width: 330px;
        height: 280px;
        background-color: black;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
        margin-top: 60px;
        border-radius: 10%;
        align-items: center;
        justify-content: center;
        display: flex;

    }
.s3::before {
        content: "";       
        position: absolute;
        height: 430px;
        width: 430px;
        background-image: conic-gradient($principal 40deg, transparent 250deg);
        animation: rotar 2s linear infinite;           
    }
@keyframes rotar {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(-360deg);
        }
    }
.s3::after{
        content: "";
        position: absolute;        
        width: 320px;
        height: 270px;
        background-image: url(../../assets/images/items/group-ntf-04.png);
        border-radius: 10%;

    }

.column-right {
    margin-top: 60px;
    margin-left: 0px;    
    .column-top {
        margin-top: -20px;
        margin-left: 220px;
        @include mobile {
            margin-left: auto;
            margin-right: auto;
        }
    }  
    img {
        padding-top: 0px;
        position: absolute;
        padding-left: 70px;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    @include mobile {  
        padding-top: 15px;            
        img {
        position: relative;
        width: 100%;
        margin-left: -10px;
        padding-left: 0;
        }  
     }
}


.page-title{
    margin-top: 50px;
    margin-bottom: 60px;    
    @include mobile {
        margin-top: 0px;        
    }
    .block-text{
        .sub-title{
            margin-bottom: -20px;
            &::after {
                bottom: 40px
            }     
            @include mobile {
                margin-bottom: 25px;
                &::after {
                    bottom: 5px
                }  
            }      
        }
    }
    .about-img {
        margin-top: 20px;
        @include mobile {
            margin-top: 0px;
            margin-left: auto;
            margin-right: auto;
        }
    } 
    
}

.page-title2{
    margin-top: 50px;
    margin-bottom: 60px;    
    @include mobile {
        
    }
    .block-text{
        .sub-title{
            margin-bottom: -20px;
            &::after {
                bottom: 40px
            }     
            @include mobile {
                margin-bottom: 25px;
                &::after {
                    bottom: 35px
                }  
            }      
        }
    }
    .about-img {
        margin-top: 20px;
        @include mobile {
            margin-top: 0px;
        }
    } 
    
}

