@use './../../scss/' as *;

.gallery-top{
    align-items: center;
}

.img-carousel {
    max-width: 100vh;
    width: 100%px;
    align-items: center;

}

.item-carousel {
    width: 100%;
    align-items: center;

}

.carousel-margin {
    margin-top: 100px;    
}
.ticket-check {
    margin-bottom: 150px;
    margin-top: 130px;
    
    .img-check {
        max-width: 120%;
        margin-left: 00px;
    }
    .block-text {
        margin-left: 80px;
        padding-top: 70px;
        text-align: start;
    .title {
        font-size: 42px;
        padding-bottom: 20px;
        color: #0e854f;
    }
    .desc2 {
        font-size: 27px;
        span {
            font-weight: 700;
        }
    }
    .icon-check {
        color:#0e854f;
    }
    .desc3 {
        padding-top: 30px;
        font-size: 30px;
        span {font-size: 55px;
        color: $principal;}
        font-weight: 700;
    }
}
    @include mobile {
        margin-top: 50px;        
        .img-check {
            width: 90vw;
            margin-left: -20px;
            padding-left: 0;
        }
        .block-text {
            padding-top: 20px;
            margin-left: 0;
            .title {
                font-size: 32px;
            }
            .desc2 {
                font-size: 20px;
            }
        }
    }}

    .reproductor {
        left: 15%;
        width: 70%;
        height: 70%;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 30px!important;
        @include mobile {
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .reproductor2 {
        padding-top: 0px;
        margin-bottom: -80px;
        left: 15%;
        width: 70%;
        height: 70%;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 40px!important;
        @include mobile {
            left: 0;
            width: 100%;
            height: 100%;
            padding-bottom: 40px;
        }
    }
 
