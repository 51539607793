@use './../../scss/' as *;
    

    
    .marginExtra {
       margin-left: -230px !important;
       margin-bottom: -20px !important;
    }
    .marginExtra2 {
        margin-left: -260px !important;
        margin-bottom: -20px !important;
     }
    .cara1 {
        font-size: 25px !important;
    }
    
    
    .button-action {
        margin-top: 40px;

        .style-2.btn-action {
            padding: 15px 41px;
        }
    }

      
      