@use './../../scss/' as *;
.hero-slider {
    margin-top: 0px;
    padding: 85px 0;
   
    @include tablet {
        padding: 0;
    } 
    

    &.style-2 {
        padding: 116px 0 327px;

        @include mobile {
            padding: 50px 0 100px;
        }
    }

    // background: url('../../../assets/images/shape/shape-01.png') no-repeat;
}

.home-3 .hero-slider {
    padding: 0px 0;
    @include tablet {
        margin-top: 40px;
    }
    @include mobile {
        margin-top: 90px;
        .block-text {  
            .title {                
            }          
        }
    }
    .block-text {
        justify-content: center;

        text-align: center;
        .title {
            font-size: 40px;
            @include mobile {
                font-size: 26px;
            }
        }
        .desc2 {
            color: red;   
            font-weight: 900; 
            font-size:38px;    
            font-family:Arial, Helvetica, sans-serif;
            @include mobile {
                margin-left: 0px;
                margin-right: 0;
                font-size: 24px;
                width: 100%;
            }
        }

    }
}

.section-about {
    margin-top: 0px;
    padding: 170px 0;
    @include tablet {
        margin-top: 400px;
        padding: 100px 0;    
        margin-left: auto;
        margin-right: auto;   
        margin-bottom: -100px;
    }
    @include mobile {  
        margin-bottom: 0;
        margin-left: 0px;
        margin-right: 0;
        padding-bottom: 0;
        width: 100vw;
    }
    .block-text{
        padding-left: 15px;
        @include mobile {
            margin-top: -100px;  
            padding-left: 0; 
            }
        .sub-title {
            &::after {
                left: 0%;
                @include mobile {
                    left: 47%;
                }        
    }}}
    .group-image {    
        padding-top: 140px;          
        margin-left: -70px;
        display: flex;
        align-items: center;
        transform: scale(0.7);
        @include tablet { 
            padding-top: 0;         
            transform: scale(0.8);
            margin-left: auto;
            margin-right: auto;        }
        @include mobile {          
           transform: scale(0.6);
           margin-left: -130px;          
           margin-top: 60px;
           margin-right: 0px;
           margin-bottom: -300px;           
        }
        .left {
            margin-right: 30px;

            @include mobile {
                margin-bottom: 30px;
                margin-right: 0;
            }
        }
        .right .item:nth-child(1) {
            margin-bottom: 30px;
        }
    }
}

.section-ticket {   
    .block-text {
        .sub-title {
            margin: 0;
            padding: 0;
            &::after {
                width: 0;
            }
            .desc {
                margin: 0;
                padding: 0;
            }
        }
    }
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 60px; 
    @include mobile {
        margin-top: 0px;
        padding-top: 160px;
        .block-text {
            padding: 0;
            .sub-title{
                font-size: 26px;
                line-height: 1.1;
            }
        }
    }
    
    .new-title {
        text-align: center;
        margin-bottom: 20px;
        font-size: 60px;
    }
               
        .ticket-img {
          width: 100%;
          min-height: 100%;
          margin-left: 0px;
          margin-top: 0px;
          @include tablet {
            margin-left: auto;
            margin-right: auto;
          }
          @include mobile {
            width: 350px;
            padding-top: 0px;
            margin-left: -10px;
            margin-right: auto;
            align-items: center;
          }
    
   
    }
    
    .btn-ticket {
        margin-top: 0px;
    }
    @include tablet {
        margin-top: 400px;
        .block-text {
            .sub-title {
                &::after {
                    left: 0;}
            }
        }   
       
    }
    @include mobile {
        margin-top: 150px;
        margin-bottom: 0;
        padding-bottom: 0;
        .new-title {
            font-size: 50px;
        }
        .block-text {
            margin-top: 50px;     
         
        }       
    }
}

.section-info {
    align-items: center;
    text-align: center;
}
.ticket-theme {
        .block-text {
        align-items: center;
        text-align: center;
    }
}
.section-responsive {
    margin-top: 0px;
    padding: 0px 0;
    display: flex;
    @include tablet {
        align-items: center;
        margin-top: 100px;
        padding: 0px 0;    
        margin-left: auto;
        margin-right: auto;   
        margin-bottom: -100px;
    }
    @include mobile {        
        margin-bottom: -150px;
        margin-left: 0px;
        margin-right: 0;
        padding-bottom: 0;
        margin-top: 0px;
        width: 92vw;
    }
    .block-text{
        padding-left: 15px;
        @include mobile {
            padding-left: 22px;
        }
        .sub-title {
            &::after {
                left: 0%;
                @include tablet {
                    left: 48%;
                }
                @include mobile {
                    left: 46%;
                }
    }}}
    .group-image {  
        padding-left: 140px;
        padding-top: 0px;          
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        transform: scale(0.7);
        @include tablet {    
            padding-left: 0;         
            padding-top: 0;         
            transform: scale(0.8);
            margin-left: auto;
            margin-right: auto;
            align-items: center;
        }
        @include mobile {          
           transform: scale(.38);
           margin-left: 0px;          
           padding-top: 0px;
           margin-right: 0px;
           padding-left: 0px;           
        }
        .group-left{
            margin-top: -280px; 
            @include tablet {
                margin-left: 50px;       
            }
            @include mobile {
                margin-top: -550px;
                margin-left: 0px;
                margin-bottom: 0;
            }
        }
        .left {
            margin-right: 30px;

            @include mobile {
                margin-bottom: 30px;
                margin-right: 0;
            }
        }
        .right .item:nth-child(1) {
            margin-bottom: 30px;
        }
    }
}




.page {
    background: url('./../../assets/images/background/bg-about.jpg');
}

.about .section-about {
    margin-top: 130px;
    padding: 0px 0;      
    margin-bottom: 0px;   
}
.group-image {
    display: flex;
    margin-top: -40px;
    
      
}
.service-subtitle {   
    margin-top: 40px; 
    .sub-title{
        margin-bottom: -20px;
        &::after {
            bottom: 40px
        }
    }
    .group-image {
            margin-left: 1009px;
        }   
}

.home-3 .section-responsive {
    padding: 145px 0 0;

    .group-image {
        margin-top: 42px;
        align-items: flex-start;
        position: relative;
        .left {
            margin-right: 23px;
        }
               
    }
    @include tablet {
        padding: 40px 0 120px;
    }

    @include mobile {
        padding: 40px 0 80px;
    }
}

.section-info {
    padding: 158px 0 0;
}
.porfolio {
    padding: 150px 0 139px;

    @include tablet {
        padding: 100px 0;
    }
}
.about .porfolio  {
    padding: 147px 0 0;
    @include mobile {
        padding: 100px 0 0;
    }
}

.montono {
    background-color: #212121;
    margin-top: 100px;
    .block-text {
        padding-top: 50px;
        .title {
        color: white;
        font-size: 25px;
        font-family: "roboto";
        
    }
    .sub-title{ 
        color: white;  
        @include mobile {
            margin-top: 20px;
            margin-bottom: 40px; 
            line-height: 1;
            font-size: 30px;
        }      
        &::after {
            width: 0;
        }}
        @include mobile {
        .desc {
            text-align: left;
            padding-left: 10px;
            padding-right: 0;
            font-size: 19px;
            line-height: 1.1;
            margin-bottom: 35px;
            }
        .desc1 {
            text-align: left;
            font-size: 22px;
            padding-left: 10px;
            padding-top: 0;
        }
        }
       
    }
    .text-area {
        margin-left: -125px;
        @include mobile {
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 50px;
        }
    }
    @include tablet {
        margin-bottom: -300px;
    }
}

.road-map {
    padding: 113px 0px 160px;

    @include tablet {
        padding: 100px 0;
    }
    @include mobile {
        margin-top: 400px;
    }
}

.home-3 .montono {
    padding: 40px 0 0;
}
.home-3 .road-map {
    padding: 138px 0px 0;
}

.roadmap .road-map {
    padding: 143px 0px 160px;
}

.service .road-map {
    padding: 144px 0px 11px;
}

.newsletter {
    .newsletter__body {
        @include flex(center,space-between);
        background: $bg-item-1;
        padding: 51px 72px 47px;
        border-radius: 20px;

        @include tablet {
            flex-wrap: wrap;

            .body__form {
                width: 100% !important;
                padding-left: 0 !important;
                padding-top: 30px;
            }
        }

        @include mobile {
            padding: 51px 30px 47px;
        }

        .body__form {
            width: 60%;
            padding-left: 43px;
        }
        
    }
}

.home-3 .newsletter {
    padding: 162px 0 99px;
}

.clients {  
    padding: 139px 0 128px;
    margin-top: 0px;
    @include tablet {
        padding: 100px 0;
        margin-top: 0px;
    }
    @include mobile {
        margin-top: -80px;

    }
}
.home-2 .clients {
    padding: 139px 0 144px;
}
.technology {
    padding: 123px 0 0;
}
.home-3 .clients {
    padding: 129px 0 0;
}

.about .clients {
    padding: 111px 0 115px;
}

.page-clients .clients {
    padding-bottom: 0;
}

.testimonials {
    padding: 137px 0 142px;

    @include tablet {
        padding: 100px 0;
    }
}

.action {
    padding: 149px 0 122px;
    @include mobile {
        margin-top: 100px;
        margin-bottom: 00px;
      }
    .action__body {
        background: transparent;
        border: 2px solid $principal-dark;
        border-radius: 20px;
        padding: 61px 72px;
        box-shadow: 0 0 5px rgb(0, 0, 0),
                    0 0 10px rgb(35, 0, 39),
                    0 0 20px rgba(15, 1, 15, 0),
                    0 0 30px rgb(104, 1, 117);

        @include flex(center,space-between);

        @include tablet {
            flex-wrap: wrap;

            .block-text {
                margin-bottom: 30px;
            }
        }

        @include mobile {
            padding: 61px 30px;
        }

        .btn-action.style-2 {
            padding: 18px 60px;
            font-size: 20px;
        }
    }
}

.page-blog-single .action {
    padding: 168px 0 122px;
}

.page-contact .action {
    padding: 143px 0 122px;
}

.about .action {
    padding: 167px 0 122px;
}
.page-clients .action {
    padding: 57px 0 122px;
}

.home-2 .testimonial {
    padding: 140px 0 132px;
}

.home-3 .testimonial {
    padding: 105px 0 0;
}
.home-3 .section-counter {
    padding: 156px 0 0;
}
.blog {
    padding: 122px 0;
}

.home-3 .blog {
    padding: 137px 0 0;
}
.home-2 .partners {
    padding: 46px 0 119px;
}

.roadmap .faq {
    padding: 143px 0 0;
}
.page-faq .faq {
    padding: 143px 0 121px;
}

.home-3 .faq {
    padding: 140px 0 0;
}
.page-faq .action {
    padding: 167px 0 122px;
}

.visions {
    margin-top: 100px;
    padding: 169px 0 167px;
    @include tablet {
        padding: 100px 0;
    }
    @include mobile {
        margin-top: 0;
    }
    .group-image {
        transform: scale(.8);
        margin-left: -100px;
        margin-top: -70px;

        @include tablet {
            margin-left: 0;
            margin-top: 60px;
            img {
                width: 100%;
            }
        }
    }
        .block-text {
        margin-left: 0;
        padding-left: 0;
        .sub-title {
            &::after {
                @include mobile {
                    left: 47%;
                }
            }
        }
        p{
            margin-left: 20px;
            text-align: left;
            span {
                &::after {
                    content:'';
                    left: -18px;
                    top: 8px;
                    width: 6px;
                    height: 6px;
                    background-color: $principal-dark;
                    position: absolute;
                    color: $principal;
            }
        }
        
    }}
}

.services {   
    @include mobile {      
        margin-left: 0;
        margin-right: 0;
    }
    .block-text {
        padding-top: 21px;
        color: white;
        
        @include mobile{
            ul {
                margin-left: 0;     
                padding-left: 20px;
                padding-right: 20px;           
                li {
                    text-align: left;
                    margin-left: 0px;
                }
              
            }
        }
    }
    .group-image {
        margin-top: 40px;
        position: absolute;
        display: flex;
        transition: all .6s ease-in-out;
        @include mobile {
            margin-top: 140px;
            width: 330px;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
        }
        &:hover {
            .btn-world {
                padding: 11px 45px;
                margin-left: 165px;      
                background-color: #000000;
                @include mobile {
                    padding: 11px 18px;
                    margin-left: 30px;
                    margin-top: 30px;
                    font-size: 15px;
                }
          
            }            
            
            .image1 img {
                width: 250px;
                height: 250px;
                margin-left: -10px;
                margin-top: 50px;
                @include mobile {
                    margin-top: -30px;
                    width: 150px;
                    height: 150px;
                    margin-left: auto;
                    margin-right: auto;

                }
              
            }
            .image2 {
                opacity: 1;
                transform: scale(1);
                @include mobile {
                    margin-top: 220px;
                }
            }
        }
        .image1 {
            margin-top: 140px;
            position: absolute;
            margin-left: 150px;
            @include mobile {
                margin-left: 30px;
                margin-right: 0;
                margin-top: 230px;
            }
           
            img {
                width: 300px;
                height: 300px;
                margin-top: 20px;
                margin-left: -30px;
                transition: all 0.6s ease-in-out;
                @include mobile {
                    margin-left: auto;
                    margin-right: auto;
                    width: 200px;
                    height: 200px;
                    margin-top: -60px;
                    
                }  
            }
        }
        .image2 {
            transform: scale(.1);
            margin-top: 70px;
            position: absolute;
            margin-left: 15px;
            transition: all 0.6s ease-in-out;                 
            animation: giro 10s infinite linear;
            opacity: 0;
            @include mobile {
                margin-top: 210px;
                margin-left: 25px;
            }
            }
        @keyframes giro {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }            
        }
        .btn-world {
            margin-top: 290px;
            margin-left: 180px;
            z-index: 1000;   
            padding: 9px 35px;
            border: 2px solid $principal;
            color: $white;
            background-color: #000000;
            border-radius: 27px;
            transition: color 0.4s linear;
            position: relative;
            overflow: hidden;
            display: inline-block;
            font-family: $font-main;
            transition: all 0.6s ease-in-out;
            box-shadow: 0 0 5px rgb(190, 18, 190),
                        0 0 10px rgb(104, 1, 117); 
            @include mobile {
                padding: 9px 20px;
                margin-left: 25px;
                margin-top: 0px;
                top: 90px;

            }               

            &:hover {
                background-color: #000000;

                box-shadow: 0 0 5px rgb(0, 0, 0),
                0 0 10px rgb(35, 0, 39),
                0 0 20px rgba(15, 1, 15, 0),
                0 0 30px rgb(104, 1, 117);
                color: $principal;
              
            }
        
            &::before,
            &::after {
                position: absolute;
                z-index: -1;
                top: 50%;
                content: "";
                width: 20px;
                height: 20px;
                background-color: $main-color;
                border-radius: 50%;
             
            }
            &::before {
                left: -20px;
                transform: translate(-50%, -50%);       
            }
            &::after {
                right: -20px;
                transform: translate(50%, -50%);       
            }               
        }
        @include tablet {
            margin-left: 0;
            margin-top: 60px;
            img {
                width: 100%;
            }
        }        
    }

}

.collection {
    margin-top: 140px;
    @include tablet {
        padding: 60px 0;
    }

    .group-image {
        width: 100vh;
        padding-right: 0px;
        position: relative;

        @include tablet {
            padding-right: 0;
        }
    }
}

.collections .action {
    padding: 159px 0 122px;
}

.blog-list {
    padding: 168px 0 20px;
}

.blog-grid {
    padding: 168px 0 11px;
}

.contact {
    margin-bottom: -120px;
    padding: 144px 0 168px; 
}

@include tablet {
    .section-info {
        padding: 0;
    }
}

@include mobile {
    .action,
    .about .section-about,
    .about .action,
    .collections .action,
    .home-2 .road-map,
    .home-2 .clients ,
    .home-2 .testimonial,
    .roadmap .road-map,
    .page-blog-single .action,
    .page-faq .faq,
    .contact,
    .page-contact .action,
    .page-faq .action,
    .home-3 .road-map,
    .home-3 .clients,
    .home-3 .testimonial,
    .home-3 .newsletter {
        padding: 100px 0;
    }

    .about .clients {
        padding: 50px 0 40px;
    }

    .page-clients .action {
        padding: 0px 0 100px;
    }

    .roadmap .faq,
    .service .road-map,
    .blog-grid {
        padding-top: 100px;
    }

    .home-3 .montono , .technology, .home-3 .section-counter {
        padding: 0;
    }
    .home-3 .blog ,.home-3 .faq {
        padding: 100px 0 0;
    }

}